import React from 'react';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import LogoIcon from '../svg/LogoIcon';
import Layout from '../components/layout/Layout';

export default () => (
  <>
    <Helmet>
      <title>特定商取引法に基づく表記 | キュブライト株式会社</title>
      <description>特定商取引に関する法律 第11条に基づき、以下明示します。</description>
      <meta property="og:url" content="https://www.kubright.co.jp/trading/" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="特定商取引法に基づく表記 | キュブライト株式会社" />
      <meta
        property="og:description"
        content="特定商取引に関する法律 第11条に基づき、以下明示します。"
      />
      <meta property="og:site_name" content="特定商取引法に基づく表記 | キュブライト株式会社" />
      <script src="https://sdk.form.run/js/v2/embed.js" />
    </Helmet>

    <header className="sticky top-0 bg-white shadow">
      <div className="container flex flex-col sm:flex-row justify-between items-center mx-auto py-4 px-8">
        <div className="flex items-center text-2xl">
          <Link to="/" className="w-64 mr-3">
            <LogoIcon />
          </Link>
        </div>
      </div>
    </header>

    <main className="text-gray-900">
      <section className="pt-20 md:pt-20">
        <div className="container mx-auto px-8">
          <div className="pb-16">
            <h1 className="text-lg">特定商取引法に基づく表記</h1>
            <p className="text-sm">特定商取引に関する法律 第11条に基づき、以下明示します。</p>
          </div>
          <div className="divide-y divide-gray-400 pb-8">
            <div className="py-2 text-gray-600">販売業者</div>
            <div className="py-2">キュブライト株式会社</div>
          </div>
          <div className="divide-y divide-gray-400 pb-8">
            <div className="py-2 text-gray-600">運営責任者</div>
            <div className="py-2">梅舟 柄全</div>
          </div>
          <div className="divide-y divide-gray-400 pb-8">
            <div className="py-2 text-gray-600">住所</div>
            <div className="py-2">〒142-0041 東京都品川区戸越1-14-14</div>
          </div>
          <div className="divide-y divide-gray-400 pb-8">
            <div className="py-2 text-gray-600">電話番号</div>
            <div className="py-2">03-3788-9577</div>
          </div>
          <div className="divide-y divide-gray-400 pb-8">
            <div className="py-2 text-gray-600">メールアドレス</div>
            <div className="py-2">info@kubright.co.jp</div>
          </div>
          <div className="divide-y divide-gray-400 pb-8">
            <div className="py-2 text-gray-600">商品代金・商品以外の必要代金</div>
            <ul className="py-2 list-disc list-inside">
              <li>商品代金は各商品ページに消費税込の価格を表示しております。</li>
              <li>
                銀行振込手数料、後払い手数料、金利手数料、分割手数料はお客様ご負担となります。
              </li>
              <li>
                配送手数料は原則無料ですが、特別配送の場合等には配送料を各商品ページに別途明記致します。
              </li>
              <li>
                インターネットの利用に関わる全ての料金(インターネット通信費・パソコン・スマートフォン代など)はお客様ご負担となります。
              </li>
            </ul>
          </div>
          <div className="divide-y divide-gray-400 pb-8">
            <div className="py-2 text-gray-600">注文方法</div>
            <ul className="py-2 list-decimal list-inside">
              <li>当サイト上で、購入したい商品・サービスとその数量を選択します。</li>
              <li>注文者情報を入力後に「購入」または「お支払い」ボタンを押下します。</li>
              <li>
                配送手数料は原則無料ですが、特別配送の場合等には配送料を各商品ページに別途明記致します。
              </li>
              <li>「ご注文完了のご案内」メールが到着した時点で商品のご注文が確定します。</li>
            </ul>
          </div>
          <div className="divide-y divide-gray-400 pb-8">
            <div className="py-2 text-gray-600">支払方法</div>
            <ul className="py-2 list-disc list-inside">
              <li>各種電子マネー決済</li>
              <li>クレジットカード決済</li>
              <li>銀行振込</li>
              <li>後払い</li>
              <li>分割払い</li>
            </ul>
          </div>
          <div className="divide-y divide-gray-400 pb-8">
            <div className="py-2 text-gray-600">代金のお支払い時期</div>
            <div className="py-2">
              ご選択いただいたお支払い方法によって異なりますので、各決済代行会社にお問い合わせください。
            </div>
          </div>
          <div className="divide-y divide-gray-400 pb-8">
            <div className="py-2 text-gray-600">支払期限</div>
            <div className="py-2">
              ご請求はご注文完了後、即時となります。
              お客様の口座からの引き落とし時期につきましては、ご利用のクレジットカード会社・キャリアに準じます。
            </div>
          </div>
          <div className="divide-y divide-gray-400 pb-8">
            <div className="py-2 text-gray-600">引渡し時期</div>
            <div className="py-2">
              ご購入いただいた商品・サービスは、各商品ページにて記載してあります「お届け目安」時期に配送となります。
            </div>
          </div>
          <div className="divide-y divide-gray-400 pb-8">
            <div className="py-2 text-gray-600">返品・交換について</div>
            <ul className="py-2 list-disc list-inside">
              <li>
                お客様のご注文に基づく表示による商品の確定時をもって正式な受注手続きの完了とします。
              </li>
              <li>お客様都合による、ご注文後の返品・交換等はお受けできません。</li>
              <li>
                クーリングオフ期間経過後のお客様のご都合による返品・交換、サービスのキャンセルは一切行っておりません。
              </li>
              <li>商品不良の場合は、商品不良の確認後、交換対応をさせていただきます。</li>
            </ul>
          </div>
        </div>
      </section>
    </main>
    <footer className="container mx-auto py-8 px-3 mt-48 mb-8 text-gray-800">
      <p className="text-sm text-gray-600 pt-12 text-center">
        Copyright © 2007 KUBRIGHT Corporation. All Rights Reserved.
      </p>
    </footer>
  </>
);
